/**
 * Validates a list of files based on various criteria.
 *
 * @param {FileList} files - The list of files to validate.
 * @param {number} maxFiles - Maximum number of files allowed.
 * @param {number} maxTotalSize - Maximum total size of all files in bytes.
 * @param {number} maxFileSize - Maximum size for an individual file in bytes.
 * @param {Array<string>} acceptedFormats - Array of accepted MIME types.
 * @param {number} minWidth - Minimum width for image files.
 * @param {number} minHeight - Minimum height for image files.
 * @returns {Promise<Object>} - the fileErrors array and a generalError if they are present.
 */
async function validateFiles(
  files,
  maxFiles,
  maxTotalSize,
  maxFileSize,
  acceptedFormats,
  minWidth,
  minHeight
) {
  let fileErrors = [];
  let generalError = null;

  generalError = checkMaxFiles(files, maxFiles);
  if (generalError) {
    return { fileErrors, generalError };
  }

  generalError = checkMaxTotalSize(files, maxTotalSize);
  if (generalError) {
    return { fileErrors, generalError };
  }

  for (let file of files) {
    const fileSizeError = checkFileSize(file, maxFileSize);
    const formatError = checkAcceptedFormat(file, acceptedFormats);
    if (fileSizeError) fileErrors.push(fileSizeError);
    if (formatError) fileErrors.push(formatError);
  }

  if (minWidth || minHeight) {
    const dimensionErrors = await checkImageDimensions(files, minWidth, minHeight);
    fileErrors = fileErrors.concat(dimensionErrors);
  }

  return { fileErrors, generalError };
}

/**
 * Checks if the number of files exceeds the maximum allowed.
 *
 * @param {FileList} files - The list of files to check.
 * @param {number} maxFiles - Maximum number of files allowed.
 * @returns {Object|null} - A general error or null.
 */
function checkMaxFiles(files, maxFiles) {
  if (files.length > maxFiles) {
    return `You can upload a maximum of ${maxFiles} files. Try removing some files to continue.`;
  }
  return null;
}

/**
 * Checks if the total size of the files exceeds the maximum allowed.
 *
 * @param {FileList} files - The list of files to check.
 * @param {number} maxTotalSize - Maximum total size of all files in bytes.
 * @returns {Object|null} - A general error or null.
 */
function checkMaxTotalSize(files, maxTotalSize) {
  const totalSize = Array.from(files).reduce((acc, file) => acc + file.size, 0);
  if (maxTotalSize && totalSize > maxTotalSize) {
    return `The total size of your files is larger than our size limit ${(
      maxTotalSize /
      1024 /
      1024
    ).toFixed(0)} MB. Try uploading fewer files, or smaller files.`;
  }
  return null;
}

/**
 * Checks if the size of an individual file exceeds the maximum allowed.
 *
 * @param {File} file - The file to check.
 * @param {number} maxFileSize - Maximum size for an individual file in bytes.
 * @returns {Object|null} - A file error or null.
 */
function checkFileSize(file, maxFileSize) {
  if (file.size > maxFileSize) {
    const reason = `There is a size limit of ${(maxFileSize / 1024 / 1024).toFixed(
      0
    )} MB per file. Try uploading a smaller file.`;
    return { file, reason };
  }
  return null;
}

/**
 * Checks if the file format is accepted.
 *
 * @param {File} file - The file to check.
 * @param {Array<string>} acceptedFormats - Array of accepted MIME types.
 * @returns {Object|null} - A file error or null.
 */
function checkAcceptedFormat(file, acceptedFormats) {
  if (acceptedFormats.length === 0) return null;
  if (acceptedFormats.includes(file.type)) return null;

  const fileTypeParts = file.type.split("/");
  for (const format of acceptedFormats) {
    const formatParts = format.split("/");
    if (formatParts[0] === fileTypeParts[0] && formatParts[1] === "*") return null;
  }

  const reason = `This file isn't a format we accept. Try uploading a different file format.`;
  return { file, reason };
}

/**
 * Checks if the dimensions of image files meet the minimum requirements.
 *
 * @param {FileList} files - The list of files to check.
 * @param {number} minWidth - Minimum width for image files.
 * @param {number} minHeight - Minimum height for image files.
 * @returns {Promise<Array<Object>>} - An array of file errors.
 */
function checkImageDimensions(files, minWidth, minHeight) {
  const imageFiles = Array.from(files).filter((file) => file.type.startsWith("image/"));
  if (imageFiles.length === 0) return Promise.resolve([]);

  const promises = imageFiles.map(readImageDimensions);
  return Promise.all(promises)
    .then((results) => {
      const failures = [];
      for (const result of results) {
        if (result.width < minWidth || result.height < minHeight) {
          const reason = `This file doesn't meet the minimum dimensions we need. At minimum, the file you upload should be ${minWidth}x${minHeight}.`;
          failures.push({ file: result.file, reason });
        }
      }
      return failures;
    })
    .catch((error) => {
      console.error("Error:", error);
      const reason = `An error occurred while validating the image dimensions: ${error.reason}`;
      return [{ file: error.file, reason }];
    });
}

/**
 * Reads the dimensions of an image file.
 *
 * @param {File} file - The image file to read.
 * @returns {Promise<Object>} - An object containing the file name, width, and height.
 */
function readImageDimensions(file) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ file, name: file.name, width: img.width, height: img.height });
    img.onerror = () => reject({ file, reason: "Failed to load image" });

    const reader = new FileReader();
    reader.onload = (event) => (img.src = event.target.result);
    reader.onerror = () => reject({ file, reason: "Failed to read file" });

    reader.readAsDataURL(file);
  });
}

/**
 * Sends an error message as a custom event.
 *
 * @param {string} message - The error message to send.
 * @param {Element} targetElement - The element to dispatch events from.
 */
function sendError(message, targetElement) {
  targetElement.setCustomValidity(message);
  targetElement.dispatchEvent(new CustomEvent("fileUploadError", { detail: { message } }));
}

export { validateFiles, sendError };
